import '@angular/compiler';
import {APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom} from '@angular/core';
import {bootstrapApplication} from "@angular/platform-browser";

import {environment} from 'environments/environment';
import {AppComponent} from "./app/app.component";
import {provideRouter, Router} from '@angular/router';
import {ROUTES} from "app/routes";
import {SupabaseClient} from "packages/supabase/SupabaseClient";
import {ThemeToggle} from "packages/theme/themeToggle";
import {PlaySettingsProvider} from "codiew/settings/play-settings-provider.service";
import {
  TUI_SANITIZER,
  TuiAlertModule,
  TuiDialogModule,
  TuiHostedDropdownModule,
  TuiRootModule,
  tuiSvgSrcInterceptors
} from "@taiga-ui/core";
import {DialogBackgroundFilter} from "packages/dialog-bf-filter/dialog.background-filter";
import {Profiler} from "auth/profiler";
import {Authenticator} from "auth/authenticator";
import {Translator} from "app/i18n/translator";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi} from "@angular/common/http";
import {NgDompurifySanitizer} from "@tinkoff/ng-dompurify";
import {AppSvgInterceptor} from "icons/svg.interceptor";
import {provideTransloco, provideTranslocoLoadingTpl} from "@jsverse/transloco";
import {DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES} from "app/i18n/types";
import {provideTranslocoPersistLang} from "@jsverse/transloco-persist-lang";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideTranslocoLocale} from "@jsverse/transloco-locale";
import * as Sentry from '@sentry/angular';
import {HttpMockInterceptor} from "./environments/mock/mock-api.interceptor";
import {ROOT_CODIEW_PROVIDER, SupabaseCodiewProvider} from "./codiew/provider/codiew.provider";
import {provideRootEventDispatcher} from "./app/di";
import {CODE_SETTINGS_PROVIDER} from "./codiew/settings/types";
import {IS_DEMO, provideFeatureFlags} from "./app/feature/feature-flag.provider";
import {providePlaylistProvider} from "./codiew/provider/di";
import {WebWorkerman} from "./app/web-workers/web.workerman";
import {provideDefaultHistoryProvider, RECORD_REPLAY_CONTEXT} from "./codiew/types";

if (environment.production) {
  enableProdMode();
}
// initSentry()

if (!environment.production && window.location.hostname === 'localhost') {
  const currentUrl = window.location.href
  const newUrl = currentUrl.replace('localhost', '127.0.0.1')
  window.location.replace(newUrl)
}

bootstrapApplication(
  AppComponent,
  {
    providers: [
      provideAnimations(),
      provideRouter(ROUTES),
      provideHttpClient(
        withInterceptorsFromDi(),
        withInterceptors([])
      ),

      {
        provide: IS_DEMO,
        useValue: false,
      },
      {
        provide: RECORD_REPLAY_CONTEXT,
        useValue: undefined,
      },

      {provide: HTTP_INTERCEPTORS, useClass: HttpMockInterceptor, multi: true},

      provideFeatureFlags(),
      importProvidersFrom([TuiRootModule, TuiAlertModule, TuiHostedDropdownModule, TuiDialogModule]),
      provideRootEventDispatcher(),
      provideDefaultHistoryProvider(),
      ThemeToggle,
      DialogBackgroundFilter,
      Profiler,
      Authenticator,
      Translator,
      SupabaseClient,
      WebWorkerman,

      {
        provide: ROOT_CODIEW_PROVIDER,
        useClass: SupabaseCodiewProvider,
      },

      providePlaylistProvider(),
      {
        provide: CODE_SETTINGS_PROVIDER,
        useClass: PlaySettingsProvider,
      },

      // -----------------------------------
      // SENTRY
      // -----------------------------------
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: true,
        }),
      }, {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {
        },
        deps: [Sentry.TraceService],
        multi: true,
      },
      // -----------------------------------

      {
        provide: TUI_SANITIZER,
        useClass: NgDompurifySanitizer,
      },
      tuiSvgSrcInterceptors(AppSvgInterceptor),
      // CodiewIconsProvider(),

      provideTranslocoLoadingTpl(`loading...`),
      provideTranslocoLocale(),
      provideTransloco({
        config: {
          availableLangs: SUPPORTED_LANGUAGES,
          defaultLang: DEFAULT_LANGUAGE,
          reRenderOnLangChange: true,
          fallbackLang: DEFAULT_LANGUAGE,
          prodMode: environment.production,
          flatten: {
            aot: environment.production,
          },
          missingHandler: {
            useFallbackTranslation: true,
          }
        },
      }),
      provideTranslocoPersistLang({
        storage: {
          useValue: localStorage,
        },
      }),
    ],
  }
).catch(err => console.error(err))


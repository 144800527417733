export type FeatureFlagResponse = {
  studio: boolean
  templateEnabled: boolean
  sqlStudio: boolean
  longRecord: boolean
  stackblitz: boolean
  excalidraw: boolean

  comments: boolean,
  channel: boolean,
  playlists: boolean,

  openRegistration: boolean,

  enabledIDEComments: boolean,
  enabledIDEMeta: boolean,

  enableAudioRecord: boolean,
  enabledCodiewReply: boolean,

  aiAssistant: boolean,
}

export const defaultFeatures: FeatureFlagResponse = {
  longRecord: false,
  studio: true,
  templateEnabled: true,
  sqlStudio: false,
  stackblitz: false,
  excalidraw: false,


  // Табы и параметры сохранения после записи
  comments: true,
  channel: false,
  playlists: false,
  //


  openRegistration: true,

  enabledIDEComments: false,
  enabledIDEMeta: false,
  // аудио запись
  enableAudioRecord: false,
  // интерактивный ответ
  enabledCodiewReply: false,

  aiAssistant: false,
}

import {inject, Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {SupabaseClient} from "packages/supabase/SupabaseClient";
import {Translator} from "app/i18n/translator";
import {Profile, ProfileFeature} from "./type";
import {defaultFeatures} from "../app/feature/flags";
import {FeatureFlagController} from "../app/feature/feature-flag.controller";

@Injectable(
  {providedIn: 'root'}
)
export class Profiler {
  private translator: Translator = inject(Translator)
  private featureFlagController = inject(FeatureFlagController)
  private supabase = inject(SupabaseClient).getClient

  loadProfile(userId: string) {
    return from(
      this.supabase
        .from('profiles')
        .select(`id, userId, name, email, avatar, lang, nickname, location, site, about, active, roles:profile_roles(roles), features:feature_flags(*)`)
        .eq('userId', userId)
        .single()
    )
      .pipe(
        // @ts-ignore
        map(res => res.data as Profile),
        tap(profile => {
          if (profile) {
            profile.lang && this.translator.changeLang(profile.lang, true)
          }

          this.enrichName(profile)
          this.enrichFeatures(profile.features || [])
        }),
      )
  }

  updateProfile(profileId: number, partialProfile: Partial<Profile>): Observable<Profile> {
    return from(
      this.supabase
        .from('profiles')
        .update(partialProfile)
        .eq('id', profileId)
        .select(`id, userId, name, email, lang, avatar, nickname, location, site, about, active, roles:profile_roles(roles)`)
        .single()
    )
      .pipe(
        tap((res) => {
          if (res.data) {
            // @ts-ignore
            const prof = res.data as Profile
            prof.lang && this.translator.changeLang(prof.lang, true)
          }
        }),
        map((res) => {
          // @ts-ignore
          const prof = res.data as Profile
          this.enrichName(prof)

          return prof
        }),

      )
  }

  private enrichName(profile: Profile) {
    if (!profile.name) {
      profile.name = String(profile.email).split('@')[0]
    }
  }

  enrichFeatures(flags: ProfileFeature[]) {
    let first = defaultFeatures

    flags.forEach(f => {
      if (f.name in defaultFeatures) {
        // @ts-ignore
        this.featureFlagController.setFlag(f.name, f.flag)
      }
    })
  }
}

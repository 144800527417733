import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractTuiThemeSwitcher } from '@taiga-ui/cdk';
import { Event as NavEvent, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import {IDE_PATH} from "../types";

@Component({
  standalone: true,
  selector: `app-scroll-body`,
  styleUrls: [`../styles-scroll.less`],
  template: ``,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollStyles extends AbstractTuiThemeSwitcher {
}

const DISABLED_SCROLL_ROUTES = [
  '/edit/',
  '/replay/',
  IDE_PATH,
  '/r/',
  '/record/',
  '/share/',
  '/exercise/',
];

@Component({
  standalone: true,
  selector: `app-scrollable`,
  template: `
    <app-scroll-body *ngIf="_scrollable | async"></app-scroll-body>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    ScrollStyles,
    NgIf,
  ],
})
export class ScrollableComponent extends AbstractTuiThemeSwitcher implements OnInit {
  @Output() onScrollableEnabled = new EventEmitter<boolean>()
  _scrollable = new BehaviorSubject(true);

  router = inject(Router);

  ngOnInit(): void {
    this.router.events
      .subscribe(ev => {
        const enabled = this.isBodyScrollEnabled(ev)
        this._scrollable.next(enabled)
        this.onScrollableEnabled.next(enabled)
      });
  }

  private isBodyScrollEnabled(ev: NavEvent) {
    return (ev instanceof NavigationEnd) && !this.isScrollableUrl(ev.url);
  }

  private isScrollableUrl(url: string) {
    return !!DISABLED_SCROLL_ROUTES.find(disabled => {
      return url.startsWith(disabled)
    })
  }
}

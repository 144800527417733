export const CABINET_ROUTE = "cabinet"
export const CABINET_ROUTE_PROFILE = "profile"
export const CABINET_ROUTE_RECORDS = "records"
export const CABINET_ROUTE_CHANNEL = "channel"
export const CABINET_ROUTE_IDE = "ide"
export const CABINET_ROUTE_SUBSCRIPTION = "subscription"

type Page = {
  id: number
  url: string
  name: string
}

export const CABINET_PAGES: Page[] = [
  {
    id: 0,
    name: "Dashboard",
    url: "",
  },
  {
    id: 1,
    name: "Profile",
    url: CABINET_ROUTE_PROFILE,
  },
  {
    id: 2,
    name: "Records",
    url: CABINET_ROUTE_RECORDS,
  },
  {
    id: 3,
    name: "Channel",
    url: CABINET_ROUTE_CHANNEL,
  },
  // {
  //   id: 4,
  //   name: "Subscription",
  //   url: CABINET_ROUTE_SUBSCRIPTION,
  // },
  {
    id: 4,
    name: "IDE Playgrounds",
    url: CABINET_ROUTE_IDE,
  },
]

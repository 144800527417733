import {InjectionToken, Provider} from "@angular/core";
import {Observable, of} from "rxjs";
import {TEMPLATE_NAMES} from "./template/type";

export type RecordReplayContext = "replay" | "record" | "ide" | "ide-view" | undefined

export const RECORD_REPLAY_CONTEXT = new InjectionToken<RecordReplayContext>("CODIEW_RECORD_REPLAY_CONTEXT")

export interface HistoryItem {
  templateId: keyof typeof TEMPLATE_NAMES
  name: string
  timestamp: number
  link: string
  user?: {
    id: number
    name: string
    avatar?: string
  }
}

export interface HistoryViewProvider {
  getItems(ctx: RecordReplayContext): Observable<HistoryItem[]>

  flush(ctx: RecordReplayContext): void

  saveItem(ctx: RecordReplayContext, it: HistoryItem): void
}

export const HISTORY_VIEW_PROVIDER = new InjectionToken<HistoryViewProvider>("HISTORY_VIEW_PROVIDER")

export class DefaultHistoryProvider implements HistoryViewProvider {
  getItems(ctx: RecordReplayContext): Observable<HistoryItem[]> {
    return of([])
  }

  flush(ctx: RecordReplayContext): void {

  }

  saveItem(ctx: RecordReplayContext, it: HistoryItem): void {

  }
}

export const provideDefaultHistoryProvider: () => Provider = () => {
  return {
    provide: HISTORY_VIEW_PROVIDER,
    useFactory: () => new DefaultHistoryProvider(),
  }
}

import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {inject, Injectable, signal} from "@angular/core";
import {Observable, of} from "rxjs";

import {FeatureFlagKeys} from "./type";
import {defaultFeatures, FeatureFlagResponse} from "./flags";

@Injectable({providedIn: 'root'})
export class FeatureFlagController {
  private http = inject(HttpClient)

  private features = signal<Record<string, boolean>>({})

  loadFlags(): Observable<Partial<FeatureFlagResponse>> {
    return this.requestFlags()
      .pipe(
        tap((features) => this.features.set(features))
      )
  }

  supports(feature: FeatureFlagKeys): boolean {
    return this.features()[feature] ?? false
  }

  public requestFlags(): Observable<Partial<FeatureFlagResponse>> {
    return of(defaultFeatures)
  }

  public setFlag(flag: keyof FeatureFlagResponse, val: boolean) {
    this.features.set({
      ...this.features(),
      [flag]: val,
    });
  }
}
